<template>
  <div>
    <!-- <v-carousel height="450" show-arrows-on-hover hide-delimiters>
      <v-carousel-item v-for="(slide, i) in slides" :key="i">
        <v-sheet :color="colors[i]" height="103%">
          <v-row class="fill-height" align="center" justify="center">
            <v-col cols="auto">
              <div class="display-3">{{ slide }} Slide</div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel> -->
    <v-sheet>
      <v-container py-12>
        <v-row>
          <v-col>
            <h2 class="text-h3 secondary--text text--lighten-2 mb-6">
              Why Choose DPI
            </h2>
            <p class="text-h6 secondary--text text--darekn-2 mb-6">
              We are a fast-growing company of highly skilled, devoted IT
              professionals with excellence in service delivery, enabling faster
              reform and higher productivity. Our services are dynamic,
              scalable, resilient, and responsive. We take an extremely
              collaborative strategy to align our co-operations with your
              company objects.
            </p>
            <v-btn outlined x-large color="secondary" to="/about"
              >Learn more</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <StrategicAlliances />

    <v-sheet dark color="black">
      <v-container py-16>
        <v-row>
          <v-col cols="12" md="4" v-for="item of whatWeDo" :key="item.autoId">
            <v-card
              color="black lighten-1 elevation-6 rounded-xl"
              height="100%"
              class="d-flex flex-column"
            >
              <v-card-text>
                <div class="d-flex flex-column" align="center">
                  <v-icon color="info" x-large class="mb-5">
                    {{ item.icon }}
                  </v-icon>
                  <span class="info--text text--lighten-5 text-h5 mb-5">{{
                    item.title
                  }}</span>
                  <p>
                    {{ item.desc }}
                  </p>
                </div>
              </v-card-text>
              <v-spacer />
              <v-card-actions>
                <v-btn
                  class="rounded-b-xl"
                  x-large
                  color="black darken-1"
                  block
                  :to="item.link"
                >
                  learn more
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
// @ is an alias to /src
import StrategicAlliances from "@/components/StrategicAlliances.vue";

export default {
  name: "Home",
  components: {
    StrategicAlliances,
  },
  data() {
    return {
      // colors: ["primary", "secondary", "alternate", "accent"],
      // slides: ["First", "Second", "Third", "Fourth"],
      whatWeDo: [
        {
          autoId: 1,
          icon: "mdi-cloud",
          title: "Cloud",
          desc:
            "Catch up the cloud-first trend and enjoy the advantage of on-demand services for increased business productivity.",
          link: "",
        },
        {
          autoId: 2,
          icon: "mdi-infinity",
          title: "DevOps",
          desc:
            "Meet the culture-driven IT approach with enhanced team collaboration for faster product delivery cycles.",
          link: "",
        },
        {
          autoId: 3,
          icon: "mdi-cogs",
          title: "Manage SVG",
          desc:
            "Gear up to modernize your IT infrastructure with market’s leading options and enhance operational efficiencies.",
          link: "",
        },
      ],
    };
  },
};
</script>
