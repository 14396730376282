<template>
  <v-app>
    <v-navigation-drawer
      app
      mobile-breakpoint
      temporary
      disable-resize-watcher
      v-model="mainNavDrawer"
      dark
      color="accent"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            DPI
          </v-list-item-title>
          <v-list-item-subtitle>
            Solution
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item nav>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>title</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item>
    </v-navigation-drawer>
    <v-app-bar
      app
      color="primary"
      shrink-on-scroll
      dark
      src="@/assets/heading-backdrop-2.jpeg"
    >
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="mainNavDrawer = !mainNavDrawer"
      />

      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>
      <template v-slot:extension>
        <v-spacer />
        <v-btn text large class="d-none d-md-flex" to="/cloud" tile>
          <span class="mr-2">Cloud Services</span>
          <v-icon>mdi-cloud</v-icon>
        </v-btn>
        <v-btn text large class="d-none d-md-flex" to="/devops" tile>
          <span class="mr-2">DevOps</span>
          <v-icon>mdi-infinity</v-icon>
        </v-btn>
        <router-link to="/">
          <v-img
            alt="DPI Logo"
            src="@/assets/dpi-new-logo.png"
            max-height="140"
            max-width="140"
            class="mx-6 elevation-6"
            contain
          />
        </router-link>
        <v-btn text large class="d-none d-md-flex" to="/svc" tile>
          <span class="mr-2">Manage SVC</span>
          <v-icon>mdi-cogs</v-icon>
        </v-btn>
        <v-btn text large class="d-none d-md-flex" to="/about" tile>
          <span class="mr-2">About Us</span>
          <v-icon>mdi-information-variant</v-icon>
        </v-btn>
        <v-spacer />
      </template>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer padless>
      <v-divider />
      <v-card flat tile width="100%" class="alternate text-center">
        <v-card-text>
          <v-btn
            v-for="item in footerMenu"
            :key="item.autoId"
            class="mx-2"
            outlined
            dark
            :to="item.link"
          >
            <v-icon :left="$vuetify.breakpoint.mdAndUp" size="24px">
              {{ item.icon }}
            </v-icon>
            <span class="hidden-sm-and-down">{{ item.title }}</span>
          </v-btn>
        </v-card-text>

        <v-card-actions class="white--text mt-4 overline">
          <v-spacer />
          ©
          {{ new Date().getFullYear() }} <strong class="ml-1">DPI</strong>
          <v-divider vertical class="mx-4" dark />
          <v-btn text x-small dark>Privacy Policy</v-btn>
          <v-divider vertical class="mx-4" dark />
          <v-btn text x-small dark>Terms of Service</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    disablemainNavDrawer: null,
    mainNavDrawer: false,
    footerMenu: [
      {
        autoId: 1,
        icon: "mdi-home",
        title: "Home",
        link: "/",
      },
      {
        autoId: 2,
        icon: "mdi-cloud",
        title: "Cloud",
        link: "/cloud",
      },
      {
        autoId: 3,
        icon: "mdi-infinity",
        title: "DevOps",
        link: "/devops",
      },
      {
        autoId: 4,
        icon: "mdi-cogs",
        title: "Manage SVG",
        link: "/svc",
      },
      {
        autoId: 5,
        icon: "mdi-information-variant",
        title: "About Us",
        link: "/about",
      },
    ],
  }),
  // HELP
  // computed: {
  //   disablemainNavDrawer: function() {
  //     if (this.$vuetify.breakpoint.mdAndDown) {
  //       this.mainNavDrawer = false;
  //     } else {
  //       this.mainNavDrawer = true;
  //     }
  //     return;
  //   },
  // },
};
</script>
