import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#91cf26',
            secondary: '#226100',
            alternate:'791d01',
            accent:'1e141f',

            link: '#2680cf',
            success: '#869d01',
            info: '#edc602',
            error: '#cb2525',

            paper: '#fff',
            gray: '#d9d9d9',
            black: '#444939'

          },
        },
      },
});
